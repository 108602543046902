<template>
  <b-card
    class="allotoutboundoutbound-edit-wrapper"
  >
    <!-- form -->
    <b-form id="allotoutboundoutboundForm" class="edit-form mt-2">

      <!--  基本信息    -->
      <b-card header="基本信息">
        <b-row>
          <!--    出库编号      -->
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="出库编号"
                label-for="outbound_no"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="outbound_no"
                  size="sm"
                  readonly
                  v-model="allotoutboundoutbound.outbound_no"
              />
            </b-form-group>
          </b-col>
          <!--    调拨类型      -->
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="调拨类型"
                label-for="allot_type"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="allot_type"
                  size="sm"
                  readonly
                  v-model="allot_type_t"
              />
<!--              <v-select
                  id="allot_type"
                  :options="getCodeOptions('allot_type')"
                  :clearable="false"
                  @input="changeSelect('allot_type',$event)"
                  v-model = "allotoutboundoutbound.allot_type"
                  class="select-size-sm"
              />-->
            </b-form-group>
          </b-col>
          <!--    责任人      -->
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="责任人"
                label-for="duty_name"
                label-size="sm"
                class="mb-1 required"
            >
<!--              <b-form-input
                  id="duty_name"
                  size="sm"
                  v-model="allotoutboundoutbound.duty_name"
                  @click="showPeopleModal()"
                  readonly
              />-->
              <b-form-input
                  id="duty_name"
                  size="sm"
                  v-model="allotoutboundoutbound.duty_name"
                  readonly
              />
            </b-form-group>
          </b-col>
          <!--    调拨日期      -->
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="调拨日期"
                label-for="allot_time"
                label-size="sm"
                class="mb-1 required"
            >

              <b-form-input
                  id="allot_time"
                  size="sm"
                  v-model="allotoutboundoutbound.allot_time"
                  readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <!--  调出仓库    -->
      <b-card header="调出仓库">
        <b-row>
          <!--调出仓库          -->
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="调出仓库"
                label-for="fromwarehouse_name"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="fromwarehouse_name"
                  size="sm"
                  v-model="allotoutboundoutbound.fromwarehouse_name"
                  readonly
              />
            </b-form-group>
          </b-col>
          <!-- 联系人         -->
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="联系人"
                label-for="from_contact"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="from_contact"
                  size="sm"
                  v-model="allotoutboundoutbound.from_contact"
                  readonly
              />
            </b-form-group>
          </b-col>
          <!-- 联系电话         -->
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="联系电话"
                label-for="from_mobile"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="from_mobile"
                  size="sm"
                  v-model="allotoutboundoutbound.from_mobile"
                  readonly
              />
            </b-form-group>
          </b-col>
          <!-- 仓库地址         -->
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="仓库地址"
                label-for="from_location"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="from_location"
                  size="sm"
                  v-model="allotoutboundoutbound.from_location"
                  readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <!--  调入仓库   -->
      <b-card header="调入仓库">
        <b-row>
          <!--   调入仓库       -->
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="仓库"
                label-for="towarehouse_name"
                label-size="sm"
                class="mb-1 required"
            >
              <b-form-input
                  id="towarehouse_name"
                  size="sm"
                  v-model="allotoutboundoutbound.towarehouse_name"
                  readonly
              />
            </b-form-group>
          </b-col>
          <!--    联系人      -->
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="联系人"
                label-for="to_contact"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="to_contact"
                  size="sm"
                  v-model="allotoutboundoutbound.to_contact"
                  readonly
              />
            </b-form-group>
          </b-col>
          <!--    联系电话       -->
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="联系电话"
                label-for="to_mobile"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="to_mobile"
                  size="sm"
                  v-model="allotoutboundoutbound.to_mobile"
                  readonly
              />
            </b-form-group>
          </b-col>
          <!--    仓库地址      -->
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="仓库地址"
                label-for="to_location"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="to_location"
                  size="sm"
                  v-model="allotoutboundoutbound.to_location"
                  readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <b-card header="商品列表">
        <stock-use-list-item
             ref="productList"  :outboundId="idItem"  v-if="selectId==true" :isLook="isLook">
        </stock-use-list-item>
      </b-card>

<!--      <b-card header="资产列表">
        <stock-use-list-item
             ref="purchaseList"  :resultResole="resultResole" :readonlyResole="true" :outboundId="idItem" :categoryId="352" v-if="selectId==true">
        </stock-use-list-item>
      </b-card>-->

      <b-card header="车辆信息">
        <b-row>
          <!--   车辆类型       -->
          <b-col md="3">

            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="车辆类型"
                label-for="car_type"
                label-size="sm"
                class="mb-1 "
                readonly
            >
              <v-select
                  id="car_type"
                  :options="getCodeOptions('car_type')"
                  :clearable="false"
                  v-model = "car_type_t"
                  @input="changeSelectCarType('car_type',$event)"
                  class="select-size-sm"
              />
            </b-form-group>

          </b-col>
          <!--   车牌       -->
          <b-col md="3">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="车牌"
                label-for="car_no"
                label-size="sm"
                class="mb-1 "
            >
              <b-form-input
                  id="car_no"
                  size="sm"
                  v-model="allotoutboundoutbound.car_no"
              />
            </b-form-group>

          </b-col>
          <!--   车型       -->
          <b-col md="3">

            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="车型"
                label-for="car_detail"
                label-size="sm"
                class="mb-1 required"
            >
              <v-select
                  id="car_detail"
                  :options="getCodeOptions('car_detail').slice(0,11)"
                  :clearable="false"
                  v-model = "car_detail_t"
                  @input="changeSelectCarDetail('car_detail',$event)"
                  class="select-size-sm"
              />
            </b-form-group>

          </b-col>

          <!--   司机姓名       -->
          <b-col md="3">

            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="司机姓名"
                label-for="car_driver"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="car_driver"
                  size="sm"
                  v-model="allotoutboundoutbound.car_driver"
              />
            </b-form-group>

          </b-col>
          <!--    司机电话      -->
          <b-col md="3">

            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="司机电话"
                label-for="car_phone"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="car_phone"
                  size="sm"
                  v-model="allotoutboundoutbound.car_phone"
              />
            </b-form-group>

          </b-col>
          <!--    运输费   -->
          <b-col md="3">

            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="运输费"
                label-for="car_carriage"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="car_carriage"
                  size="sm"
                  v-model="allotoutboundoutbound.car_carriage"
              />
            </b-form-group>

          </b-col>

          <b-col md="3">
            <modal-select
                    :label=load_label
                    type="input"
                    v-on:change="fromChildren($event,['company_name_load','company_id_load'])"
                    :params="['company_name_load','company_id_load']"
                    :value="allotoutboundoutbound.company_name_load"
                    :warehouseId="allotoutboundoutbound.fromwarehouse_id"
                    modalName="装卸队"
                    placeholder="点击选择装卸队"
            >
            </modal-select>
          </b-col>
          <b-col md="3">
            <modal-select
                    :label=transport_label
                    type="input"
                    v-on:change="fromChildren($event,['company_name_transport','company_id_transport'])"
                    :params="['company_name_transport','company_id_transport']"
                    :value="allotoutboundoutbound.company_name_transport"
                    :showAllLogistics="false"
                    :warehouseIdFrom="allotoutboundoutbound.fromwarehouse_id"
                    :warehouseIdTo="allotoutboundoutbound.towarehouse_id"
                    modalName="承运商"
                    placeholder="点击选择承运商"
            >
            </modal-select>
          </b-col>
          <!--    备注      -->
          <b-col md="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="备注"
                label-for="memo"
                label-size="sm"
                class="mb-1"
            >
              <b-form-textarea
                  id="memo"
                  rows="3"
                  max-rows="6"
                  style="margin: 5px"
                  v-model="allotoutboundoutbound.memo"
              />
            </b-form-group>
          </b-col>
<!--      附件    -->

          <b-col cols="12">
            <b-form-group
                label-cols="1"
                label-cols-lg="1"
                label="附件"
                label-for="attachments"
                label-size="sm"
                class="mb-1"
            >
              <attachment-upload  v-if="allotoutboundoutbound.loaded" :theme="'files'"
                                 :attachment_id="'attachments_wh'"
                                 :id="allotoutboundoutbound.attachments"
                                 :object_type="'allot_outbound_outbound'"
                                 :object_id="allotoutboundoutbound.outbound_id"
                                 @change="onUploaded"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

                <b-col
          cols="12"
          class="mt-50"
          v-if="isLook==0"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save(1)"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            class="mr-1"
            @click="cancel"
          >
            取消
          </b-button>
          <b-button
              variant="outline-secondary"
              @click="save(2)"
          >
            提交并打印
          </b-button>
        </b-col>
    </b-form>
    <b-modal
        id="pdfModal"
        centered
        title="PDF"
        ref="pdfModal"
        hide-footer
    >
      <attachment-upload theme="preview"
                         attachment_id="attachments"
                         :id="pdf_id"
                         :readonly="true"
                         v-if="pdf_id"
      />
      <div v-else>
        <p class="my-4">正在生成，请稍候!</p>
      </div>

    </b-modal>
    <!--  loading  -->
    <div v-show="loadingModal"><xy-loading :placeholder="loadingPlaceholder"></xy-loading>  </div>
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, reactive, ref, toRefs} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import allotoutboundoutboundStore from './allotoutboundoutboundStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, second, isEmpty} from '@core/utils/filter'
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import WareHouseSelect from '@/views/apps/warehouse/warehouse-modal/WarehouseList'
import StockUseListItem from  '@/views/apps/stock/allotoutboundoutbound-stock/StockOutboundItem'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import ModalSelect from "@/views/components/modal/ModalSelect";
import axios from '@axios'
import {getUserData} from "@/auth/utils";
import XyLoading from "@/views/components/xy/XyLoading";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    WareHouseSelect,
    UserSelect,
    StockUseListItem,
    AttachmentUpload,
    ModalSelect,
    XyLoading
  },
  data() {
    return {
      id: ref(0),
      idItem: ref(0),
      allotoutboundoutbound: ref({}),
      selectId:false,
      memoFile: "",
      isLook:0,
      allot_type_t:"",
      car_type_t:"",
      car_detail_t:"",
      load_label:'装卸队',
      transport_label:'承运商',
      user: {},
      loadingModal:false,
      loadingPlaceholder:"单据正在生成,请勿重复操作!"
    }
  },
  methods:{
    changeSelectCarType(key,event) {
      this.allotoutboundoutbound[key] = event == null ? undefined :  event.value
      this.car_type_t = getCodeLabel("car_type",this.allotoutboundoutbound.car_type)
    },
    changeSelectCarDetail(key,event) {
      this.allotoutboundoutbound[key] = event == null ? undefined :  event.value
      this.car_detail_t = getCodeLabel("car_detail",this.allotoutboundoutbound.car_detail)
    }
  },

  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('allotoutboundoutbound')) store.registerModule('allotoutboundoutbound', allotoutboundoutboundStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('allotoutboundoutbound')) store.unregisterModule('allotoutboundoutbound')
    })
    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.allotoutboundoutbound[modal[i]] =params==null?null:params[modal[i]]
      }
      this.$forceUpdate()
    }

    const edit = function() {
      store.dispatch('allotoutboundoutbound/search', {outbound_id:this.idItem}).then(res => {
        if(res.data.code==0){
          this.allotoutboundoutbound = res.data.data.list[0]
          this.id=this.allotoutboundoutbound.allot_outbound_id
          this.allotoutboundoutbound.allot_time = toDate(this.allotoutboundoutbound.allot_time)
          this.allotoutboundoutbound.duty_name = this.user.full_name
          this.allot_type_t = getCodeLabel('allot_type',this.allotoutboundoutbound.allot_type)
          this.car_type_t = getCodeLabel('car_type',this.allotoutboundoutbound.car_type)
          this.car_detail_t = getCodeLabel('car_detail',this.allotoutboundoutbound.car_detail)
          this.selectId =true
          if (this.allot_type_t == '内部调拨' || this.allot_type_t == '寄售调拨') {
            this.load_label = '装卸队'
            this.transport_label = '承运商'
            if (isEmpty(this.allotoutboundoutbound.company_name_load)) {
              //查询装卸队
              axios.post('/api/company/searchLoadTeam', {
                'allotFlag': 2,
                'warehouseId': this.allotoutboundoutbound.fromwarehouse_id,
              }).then(res => {
                if (res.data.code === 0) {
                  const list = res.data.data.list
                  if (list.length > 0) {
                    this.allotoutboundoutbound.company_name_load = list[list.length - 1].company_name
                    this.allotoutboundoutbound.company_id_load = list[list.length - 1].company_id
                    this.$forceUpdate()
                  } else {
                    this.allotoutboundoutbound.company_name_load = ''
                    this.allotoutboundoutbound.company_id_load = ''
                  }
                }
              })
            }
            if (isEmpty(this.allotoutboundoutbound.company_name_transport)) {
              //查询承运商
              axios.post('/api/company/searchLogistics', {
                'showAllLogistics': false,
                'warehouseIdFrom': this.allotoutboundoutbound.fromwarehouse_id,
                'warehouseIdTo': this.allotoutboundoutbound.towarehouse_id,
              }).then(res => {
                if (res.data.code === 0) {
                  const list = res.data.data.list
                  if (list.length > 0) {
                    this.allotoutboundoutbound.company_name_transport = list[list.length - 1].company_name
                    this.allotoutboundoutbound.company_id_transport = list[list.length - 1].company_id
                    this.$forceUpdate()
                  } else {
                    this.allotoutboundoutbound.company_name_transport = ''
                    this.allotoutboundoutbound.company_id_transport = ''
                  }
                }
              })
            }
          }
        }else{
          toast.error(res.data.data);
          this.$router.go(-1)
        }

      })

    }

    const view = function() {
      store.dispatch('allotoutboundoutbound/view', {id: this.id}).then(res => {
        this.allotoutboundoutbound = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const state = reactive({
      condition:{
      },
      curRow:undefined,
      rollbackModal:undefined,
      pdf_id:undefined,
      pdfModal:undefined,
    })
    const onUploaded = function (id, attachment, result) {
      this.allotoutboundoutbound.attachments = result
    }

    const save = function(status) {
      if (this.allot_type_t == '内部调拨' || this.allot_type_t == '寄售调拨'){
        if (isEmpty(this.car_detail_t)) {
          toast.error('请选择车型！')
          return
        }
      }

   if(confirm("确认提交吗？")) {
     let productList = this.$refs.productList.datalist
     let productListLength = productList.length
     this.allotoutboundoutbound.allot_time = second(this.allotoutboundoutbound.allot_time)
     this.loadingModal = true
     store.dispatch('allotoutboundoutbound/save',
         {
           ...this.allotoutboundoutbound,
           productList: productList,
           productListLength: productListLength,
         }
     ).then(res => {
       this.loadingModal = false
       if (status == 2) {//提交并打印
         if (res.data.code === 0) {
           let outbound_id = res.data.data.outbound_id
           this.$router.push({name: 'apps-allotoutboundoutbound-list', query: {id: this.id, pdf_id: outbound_id}});
         } else {
           toast.error(res.data.data)
         }
       } else { //仅保存
         if (res.data.code === 0) {
           toast.success('数据已保存!')
           this.$router.push({name: 'apps-allotoutboundoutbound-list', query: {id: this.id}});
         } else {
           toast.error(res.data.data)
         }
       }

     })
   }
    }



    return {
      ...toRefs(state),
      edit,
      view,
      cancel,
      save,


      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      onUploaded,
      fromChildren
    }
  },
  created() {
    const userData = getUserData()
    this.user = userData
    const idItem = this.$route.query.id || 0;
    this.idItem = Number(idItem)
    const isLook = this.$route.query.isLook || 0;
    this.isLook = Number(isLook)
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
