<template>

  <div>

        <b-table
            ref="refListTable"
            class="position-relative"
            :items="searchALlList"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :order-by.sync="orderBy"
            show-empty
            empty-text="未找到记录"
            :order-desc.sync="isSortDirDesc"
            v-model="datalist"
        >
          <template #cell(code)="data">
            {{data.item.ext['code']}}
          </template>



          <template #cell(specification)="data">
            {{data.item.ext['specification']}}
          </template>

          <template #cell(id)="data">
            {{data.item.ext['id']}}
          </template>

          <template #cell(productUnit)="data">
            {{data.item.ext['productUnit']}}
          </template>

          <template #cell(memo)="data">
            <b-form-input
                size="sm"
                v-model="data.item.memo"
                style="width: 60%; float: left"
            >
            </b-form-input>
          </template>

          <template #cell(useQty)="data"  >
            <b-form-input
                id="useQty"
                size="sm"
                v-model="data.item.ext['useQty']"
                @change="checkQty(data.item)"
                type="number"
            />
          </template>

          <template #cell(inboundQty)="data"  >
            <b-form-input
                id="inboundQty"
                size="sm"
                v-model="data.item.ext['inboundQty']"
                @change="checkQtyInbound(data.item)"
                type="number"
             />
          </template>


          <template #cell(outboundTrueQty)="data">
            {{data.item.ext['outboundTrueQty']}}
          </template>

          <template #cell(production_date)="data">
            {{data.item.ext['production_date']}}
          </template>

          <template #cell(usedQty)="data">
            {{data.item.ext['usedQty']}}
          </template>

          <template #cell(id)="data">
            {{data.item.ext['id']}}
          </template>

          <template #cell(warestatus)="data">
            {{ getCodeLabel("stock_status", data.item.warestatus) }}
          </template>

          <template #cell(productUnit)="data">
            {{ getCodeLabel("product_unit", data.item.ext['productUnit']) }}
          </template>


        </b-table>


    <!-- Table Container Card -->

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BTabs,BTab,BFormGroup,BForm
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted, toRefs } from '@vue/composition-api'
import {avatarText, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, second} from '@core/utils/filter'
import stockUseListItem from './stockUseListItem'
import stockStore from '../stockStore'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BTabs,
    BTab,
    flatPickr,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BForm,
  },
  data(){
    return {
      datalist:[],
    }
  },
  props:{
    outboundId :{
      type :Number,
      default: 0
    },
    categoryId:{
      type :Number,
      default: 0
    },
    isLook:{
      type :Number,
      default: 0
    }
  },
  methods: {
  },

  setup(props) {
    const toast = useToast()
    const { productId,isShow } = toRefs(props)
    // Register module
    if (!store.hasModule('stock')) store.registerModule('stock', stockStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('stock')) store.unregisterModule('stock')
    })



    const checkQty = function (item) {
      const cRegExp = /^[0-9]\d*$/
      if (!cRegExp.test(item.ext['useQty'])) {
        toast.error('出库数量必须是正整数!')
        item.ext['useQty'] = 0
        item.ext['outboundTrueQty'] =item.ext['maxUseQty'];
      }else{
        if(item.ext['useQty']>item.ext['maxUseQty']){
          toast.error('出库数量不能大于最大出库数量!!')
          item.ext['useQty'] = 0
          item.ext['outboundTrueQty'] =item.ext['maxUseQty'];
        }else{
          item.ext['outboundTrueQty'] =item.ext['maxUseQty']-item.ext['useQty'];
        }
      }

    }

    const checkQtyInbound = function (item) {
      const cRegExp = /^[0-9]\d*$/
      if (!cRegExp.test(item.ext['inboundQty'])) {
        toast.error('出库数量必须是正整数!')
        item.ext['inboundQty'] = 0
      }
    }

    const {
      searchALlList,
      stockMap,
      tableColumns,
      stockTable,
      batchTable,
      totalStockLock,
      totalStock,
      refetchData,
      wareHouseList,
      orderBy,
      isSortDirDesc,
      tableColumnBatch,
      batchList,

    } = stockUseListItem(
        {
          outBoundId:props.outboundId,
          categoryId:props.categoryId,
        }
    )


    return {
      searchALlList,
      batchList,
      stockMap,
      tableColumns,
      stockTable,
      batchTable,
      totalStockLock,
      totalStock,
      refetchData,
      tableColumnBatch,
      orderBy,
      isSortDirDesc,
      getCodeLabel,
      // Filter
      avatarText,
      checkQty,
      checkQtyInbound

    }
  },
  created() {

  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
